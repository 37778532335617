.main {
  display: flex;
  justify-items: center;
  width: calc(100% - 120px);
  margin: auto;
  padding-left: 40px;
  margin-left: 120px;
  flex-direction: column;
  padding-bottom: 100px;
}

.title {
  color: #fff;
  margin-top: 0;
}
.subtitle {
  color: #fff;
  opacity: 0.8;
}
.about {
  color: #fff;
  opacity: 0.8;
}
.title span {
  color: #1ff5dd;
}
.about span {
  color: #1ff5dd;
  font-weight: bold;
}
.text {
  color: #fff;
  opacity: 0.8;
  width: 50%;
}
.text span {
  color: #1ff5dd;
}
.form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 10px;
}
.form textarea,
.form input {
  margin-bottom: 13px;
  background-color: transparent;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 10px 15px;
  color: #fff;
}
::placeholder {
  color: #fff;
}
.form textarea {
  height: 120px;
}
.container {
  display: flex;
}
.form input[type="submit"] {
  cursor: pointer;
  width: 150px;
  border-radius: 30px;
  font-size: 14px;
  border-width: 2px;
}
.contactImg {
  max-width: 30%;
  margin-right: 5%;
}
.left {
  width: 65%;
}
@media screen and (max-width: 1000px) {
  .main {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}
@media screen and (max-width: 500px) {
  .contactImg {
    display: none;
  }
  .left {
    width: 100%;
  }
  .main {
    width: calc(100% - 45px);
    margin-left: 45px;
  }
  .title {
    font-size: 18px;
  }
  .about {
    font-size: 14px;
    width: 80%;
    margin: 0;
  }
  .subtitle {
    font-size: 12px;
    margin-top: 40px;
  }
}
