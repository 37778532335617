* {
  box-sizing: border-box;
  text-decoration: none;
}
body {
  background-color: #3a3e44;
  display: flex;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.App {
  display: flex;
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
}

.button {
  background-color: transparent;
  color: #f5f5f5;
  border: 2px solid #f5f5f5;
  padding: 10px 35px;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: inline-block;
  box-shadow: 0 0 25px 0 rgb(0 0 0 / 80%);
  z-index: 100;
  position: relative;
}
.button:hover {
  border-color: #1ff5dd;
  background-color: #1ff5dd;
  color: #3a3e44;
  font-weight: bold;
}
.images {
  display: flex;
}
@media screen and (max-width: 500px) {
  .button {
    font-size: 12px;
  }
}
