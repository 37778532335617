.main {
  display: flex;
  justify-items: center;
  width: calc(100% - 120px);
  margin: auto;
  padding-left: 40px;
  margin-left: 120px;
  flex-direction: row;
  padding-bottom: 100px;
}
.img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  margin: 15px;
  display: flex;
  filter: grayscale(17%);
}
.imgWrap {
  border-radius: 50%;
  width: 330px;
  height: 330px;
  background: linear-gradient(45deg, #298d82, #1ff5dd, #1ff5dd, #298d82);
  background-size: 100% 100%;

  display: flex;
  box-shadow: 0 0 25px 0 rgb(0 0 0 / 80%);
  margin: auto;
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}
.imgWrap:hover {
  animation: gradient 15s ease infinite;
  background: linear-gradient(-45deg, #298d82, #1ff5dd, #1ff5dd, #298d82);
}
.imgContainer {
  width: 50%;
}
.side {
  width: 50%;
}
.title {
  color: #fff;
}
.subtitle {
  color: #fff;
  opacity: 0.8;
}
.about {
  color: #fff;
  opacity: 0.8;
}
.title span {
  color: #1ff5dd;
}
.about span {
  color: #1ff5dd;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@media screen and (max-width: 1000px) {
  .main {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}
@media screen and (max-width: 900px) {
  .imgContainer {
    display: none;
  }

  .side {
    width: 80%;
  }
  .title {
    font-size: 18px;
  }
  .about {
    font-size: 14px;
  }
  .subtitle {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .main {
    width: calc(100% - 45px);
    margin-left: 45px;
  }
}
