nav {
  width: 120px;
  background-color: #222;
  min-height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
}
nav ul {
  margin: 0;
  color: #fff;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
nav li {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  padding: 25px 0;
}
nav li:hover {
  color: #1ff5dd;
  cursor: pointer;
  opacity: 1;
}
nav li.active {
  text-decoration: none;
}
nav a {
  color: #fff;
}
.icons {
  font-size: 30px;
  margin: auto;
  opacity: 0.7;
  margin-bottom: 10px;
}
.dt {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 5px;
  background-color: #1ff5dd;
  color: #222;
  opacity: 0.7;
}
.dt:hover {
  color: #222;
}
@media screen and (max-width: 1000px) {
  nav {
    width: 100px;
  }
}
@media screen and (max-width: 500px) {
  nav {
    width: 45px;
  }
  nav ul li span {
    display: none;
  }
  nav .dt {
    font-size: 14px;
  }
  nav .icons {
    font-size: 20px;
  }
}
