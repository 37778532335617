.main {
  display: flex;
  justify-items: center;
  width: calc(100% - 120px);
  margin: auto;
  flex-direction: column;
  padding-bottom: 80px;
  padding-left: 40px;
  margin-left: 120px;
}
.title {
  color: #fff;
  margin-top: 0;
}
.subtitle {
  color: #fff;
  opacity: 0.8;
}
.projectName {
  color: #fff;
  font-size: 25px;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: flex;
  border-radius: 50%;
}
.imgWrap {
  box-sizing: border-box;
  border-radius: 50%;
  width: 17vw;
  margin: 1.5vw;
  height: 17vw;
  padding: 5px;
  background: linear-gradient(45deg, #298d82, #1ff5dd, #1ff5dd, #298d82);
  background-size: 100% 100%;

  display: flex;
  box-shadow: 0 0 25px 0 rgb(0 0 0 / 80%);
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}
.imgWrap:hover {
  animation: gradient 15s ease infinite;
  background: linear-gradient(-45deg, #298d82, #1ff5dd, #1ff5dd, #298d82);
  cursor: pointer;
}

.images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 60px;
}
.title span {
  color: #1ff5dd;
}
.content .imgContainer {
  width: 100%;
}
.content .img,
.content .imgWrap {
  border-radius: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.content .img {
  width: 100%;
}
.icon {
  font-size: 35px;
  color: #1ff5dd;
  cursor: pointer;
  opacity: 0.7;
  margin-bottom: 20px;
  margin-top: 30px;
  display: block;
}
.icon:hover {
  opacity: 1;
}
.left {
  width: 50%;
}
.right {
  width: 40%;
}
.skill {
  padding: 10px;
  background-color: #222;
  color: #1ff5dd;
  border-radius: 10px;
  margin-right: 5px;
  font-size: 13px;
  word-break: break-all;
  margin-top: 5px;
  display: inline-block;
}
.sub {
  color: #1ff5dd;
}
.text {
  color: #fff;
  opacity: 0.8;
  max-width: 90%;
  font-size: 14px;
}
a.text:hover {
  color: #1ff5dd;
}
@media screen and (max-width: 900px) {
  .content {
    flex-direction: column-reverse;
  }
  .left,
  .right {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .main {
    padding-left: 10px;
  }
  .imgWrap {
    box-sizing: border-box;
    border-radius: 50%;
    width: 40vw;
    margin: 1.5vw;
    height: 40vw;
  }
  .main {
    width: calc(100% - 45px);
    margin-left: 45px;
    padding-left: 50px;
  }
  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 12px;
    margin-top: 40px;
  }
}
