.main {
  display: flex;
  justify-items: center;
  width: calc(100% - 120px);
  margin: auto;
  padding-left: 40px;
  margin-left: 120px;
  flex-direction: row;
  padding-bottom: 100px;
}
.img {
  width: 270px;
  height: 270px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  margin: 15px;
  display: flex;
  filter: grayscale(17%);
}
.imgWrap {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  background: linear-gradient(45deg, #298d82, #1ff5dd, #1ff5dd, #298d82);
  background-size: 100% 100%;

  display: flex;
  box-shadow: 0 0 25px 0 rgb(0 0 0 / 80%);
  margin: auto;
  background-size: 100% 100%;
  animation: gradient 15s ease infinite;
}
.imgWrap:hover {
  animation: gradient 15s ease infinite;
  background: linear-gradient(-45deg, #298d82, #1ff5dd, #1ff5dd, #298d82);
}
.imgContainer {
  width: 35%;
  display: flex;
}
.side {
  width: 35%;
}
.side:first-child {
  width: 75%;
}
.title {
  color: #fff;
}
.subtitle {
  color: #fff;
  opacity: 0.8;
}
.about {
  color: #fff;
  opacity: 0.8;
  width: 80%;
}
.title span {
  color: #1ff5dd;
}
.about span {
  color: #1ff5dd;
  font-weight: bold;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.skills h3 {
  color: #1ff5dd;
  letter-spacing: 2px;
}
.skills ul li {
  color: #fff;
  opacity: 0.7;
  width: 50%;
  display: inline-block;
}
.skills ul {
  margin: 0 0 15px 0;
  padding: 0;
}

@media screen and (max-width: 1200px) {
  .img {
    width: 200px !important;
    height: 200px !important;
    margin: 10px !important;
  }
  .imgWrap {
    width: 220px;
    height: 220px;
  }

  .main {
    width: calc(90% - 120px);
  }
}
@media screen and (max-width: 1000px) {
  .main {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}
@media screen and (max-width: 900px) {
  .imgContainer {
    display: none;
  }
  .main {
    width: calc(100% - 100px);
  }
  .side {
    width: 90% !important;
    margin: auto;
  }
  .title {
    font-size: 18px;
  }
  .about {
    font-size: 14px;
    width: 80%;
    margin: 0;
  }
  .subtitle {
    font-size: 12px;
  }
  .skills ul li {
    width: 100%;
  }
  .skills {
    font-size: 15px;
  }
}
@media screen and (max-width: 500px) {
  .main {
    width: calc(100% - 45px);
    margin-left: 45px;
  }
}
