footer {
  padding: 0;
  margin: 0;
  display: flex;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 120px);
  justify-content: center;
  flex-direction: column;
  margin-left: 120px;
}
footer ul {
  padding: 0;
  margin: 0;
  display: flex;

  justify-content: center;
}
footer ul li {
  list-style: none;
  margin: 0 20px;
  color: #fff;
  opacity: 0.7;
}
footer a {
  color: #fff;
}
.icons {
  font-size: 20px;
}
.icons:hover {
  color: #1ff5dd;
}
footer p {
  color: #fff;
  opacity: 0.7;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  margin-top: 7px;
}
@media screen and (max-width: 1000px) {
  footer {
    width: calc(100% -100px);
    margin-left: 100px;
  }
}
@media screen and (max-width: 500px) {
  footer {
    width: calc(100% - 45px);
    margin-left: 45px;
  }
}
